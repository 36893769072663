/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

import * as moment from 'moment';

export class QuickAddSpotsDialogController {
    public agencyPromise: any;
    public advertiserPromise: any;
    public brandPromise: any;
    public spot: any;
    public fileUploaded: any;
    public isciValidator: RegExp = /^[a-zA-Z0-9]+[hH]$/;
    public sessionData: any;
    public MAX_ISCI_LENGTH: any;
    public advertisers: any;
    public agencies: any;
    public brands: any;
    public promoTypes: any;
    public oneMonthFromToday: string = moment().add(1, 'months').format('MM/DD/YYYY');
    public formats: any;

    static get $inject() {
        return [
            '$filter',
            '$scope',
            '$state',
            '$mdDialog',
            '$q',
            '$timeout',
            'notFoundSpot',
            'AdvertiserResourceFactory',
            'AgencyResourceFactory',
            'BrandsResourceFactory',
            'EnumService',
            'loginService',
        ];
    }

    constructor(
        public $filter: any,
        public $scope: any,
        public $state: any,
        public $mdDialog: any,
        public $q: any,
        public $timeout: any,
        public notFoundSpot: any,
        public AdvertiserResourceFactory: any,
        public AgencyResourceFactory: any,
        public BrandsResourceFactory: any,
        public EnumService: any,
        public loginService: any,
    ) {
        /* BINDABLE : DATA */
        this.agencyPromise = $q.defer();
        this.advertiserPromise = $q.defer();
        this.brandPromise = $q.defer();
        this.spot = notFoundSpot;
        
        this.sessionData = loginService.getSessionData();
        this.MAX_ISCI_LENGTH = this.sessionData.account && this.sessionData.account.strictIsci ? 15 : null;
        this.advertisers = [];
        this.agencies = [];
        this.brands = [];
        this.fileUploaded;
        this.promoTypes = [];

        // Set default format to HD or Radio depending on the user
        let defaultFormat = 'HD';
        if (
            this.sessionData.account.disallowTv &&
            !this.sessionData.account.allowPromo &&
            this.sessionData.account.allowRadio
        ) {
            defaultFormat = 'RADIO';
            this.isciValidator = /^[a-zA-Z0-9]+[a-gi-zA-GI-Z0-9]$/;
        }
        this.spot.format = defaultFormat;

        /* EVENTS */
        //Register any event listeners
    }

    /* IMPLEMENTATION : BINDABLE */
    cancel() {
        let vm = this;
        vm.$mdDialog.hide('DO NOT CREATE');
    }

    cancelAll() {
        let vm = this;
        vm.$mdDialog.cancel();
    }

    submit() {
        let vm = this;

        vm.$mdDialog.hide({
            spot: vm.spot,
            fileUploaded: vm.fileUploaded,
        });
    }

    loadAdvertisers() {
        let vm = this;
        vm.advertiserPromise = vm.$q.defer();

        vm.AdvertiserResourceFactory.getAll(
            {
                agencyId:
                    vm.spot && vm.spot.Agency && vm.spot.Agency.id !== ''
                        ? vm.spot.Agency.id
                        : null,
                providerAccountId: vm.spot.providerAccountId ? vm.spot.providerAccountId : null,
                active: true,
            }
        ).subscribe(
            (advertisers: any) => {
                vm.advertisers = advertisers;
                //If necessary, filtering to only advertisers with a blank Agency
                if (!vm.spot.Agency || !vm.spot.Agency.id || vm.spot.Agency.id === '') {
                    vm.advertisers = vm.$filter('filter')(vm.advertisers, {
                        agencyId: null,
                    });
                }
                vm.advertiserPromise.resolve(advertisers);
            },
            (err: any) => {
                vm.advertiserPromise.resolve([]);
            }
        );

        return vm.advertiserPromise;
    }

    loadAgencies() {
        let vm = this;
        vm.agencyPromise = vm.$q.defer();

        vm.AgencyResourceFactory.getAll(
            {
                activeOnly: true
            }
            ).subscribe(
                (agencies: any) => {
                    vm.agencies = agencies.rows;
                    vm.agencyPromise.resolve(agencies.rows);
                },
                () => {
                    vm.agencyPromise.resolve([]);
                }
            );

        return vm.agencyPromise;
    }

    loadBrands() {
        let vm = this;
        vm.brandPromise = vm.$q.defer();

        if (vm.spot.Advertiser.id !== '') {
            vm.BrandsResourceFactory.getAll(
                {
                    advertiserId: vm.spot && vm.spot.Advertiser ? vm.spot.Advertiser.id : null,
                }
            ).subscribe(
                (brands: any) => {
                    vm.brands = brands;
                    vm.brandPromise.resolve(brands);
                },
                (err: any) => {
                    vm.brandPromise.resolve([]);
                }
            );

            return vm.brandPromise;
        }
    }

    changeAgency() {
        let vm = this;
        vm.advertisers = [];
        vm.brands = [];
        if (vm.spot.Advertiser && vm.spot.Advertiser.agencyId !== vm.spot.Agency.id) {
            vm.spot.Advertiser = null;
            vm.spot.Brand = null;
        }
    }

    changeAdvertiser() {
        let vm = this;
        vm.brands = [];
        if (vm.spot.Brand && vm.spot.Brand.advertiserId !== vm.spot.Advertiser.id) {
            vm.spot.Brand = null;
        }
    }

    startUpload() {
        let vm = this;
        vm.$timeout(function () {
            let button: HTMLElement = document.querySelector('#uploadMediaButton') as HTMLElement;
            if(button) {
                button.click();
            }
        });
    }

    changeFormat() {
        let vm = this;
        if (vm.spot.format === 'HD') {
            vm.isciValidator = /^[a-zA-Z0-9]+[hH]$/;
        } else {
            vm.isciValidator = /^[a-zA-Z0-9]+[a-gi-zA-GI-Z0-9]$/;
        }
    }

    changePromoType() {
        let vm = this;
        if (vm.spot.airStatus === 'UNFINISHED') {
            var confirm = vm.$mdDialog
                .confirm()
                .title('Promo Type Change')
                .textContent(
                    'This spot might not be suitable as a ready to air spot.  Do you wish to continue?” '
                )
                .ariaLabel('Promo type change to "unfinished"')
                .multiple(true)
                .ok('Yes')
                .cancel('No');
            var continueOrNot = vm.$mdDialog.show(confirm);
            continueOrNot.then(
                function () {
                    // Do nothing, because they're okay with it
                },
                function () {
                    vm.spot.airStatus = 'RTA';
                }
            );
        }
    }

    setPromoDates() {
        let vm = this;
        vm.spot.runEndDate = vm.spot.promo ? vm.oneMonthFromToday : null;
        vm.spot.runStartDate = vm.spot.promo ? vm.spot.runStartDate : null;
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    _loadAssociatedData() {
        let vm = this;
        vm.agencyPromise = this.$q.defer();
        vm.advertiserPromise = this.$q.defer();
        vm.brandPromise = this.$q.defer();

        vm.advertisers = [];
        vm.agencies = [];
        vm.brands = [];

        vm.AgencyResourceFactory.getAll(
            {
                activeOnly: true
            }).subscribe(
                (agencies: any) => {
                    vm.agencies = agencies.rows;
                    vm.agencyPromise.resolve(vm.agencies);
                },
                () => {
                    vm.agencyPromise.resolve([]);
                }
            );

        vm.AdvertiserResourceFactory.getAll(
            {
                agencyId: null,
                providerAccountId: null,
            }).subscribe(
                (advertisers: any) => {
                    vm.advertisers = advertisers;
                    vm.advertiserPromise.resolve(vm.advertisers);
                },
                () => {
                    vm.advertiserPromise.resolve([]);
                }
            );

        vm.BrandsResourceFactory.getAll({ advertiserId: null }).subscribe(
            (brands: any) => {
                vm.brands = brands;
                vm.brandPromise.resolve(vm.brands);
            },
            () => {
                vm.brandPromise.resolve([]);
            }
        );

        // Return them in a promise, so we can wait for them to finish before moving on
        return vm.$q.all([
            vm.agencyPromise,
            vm.advertiserPromise,
            vm.brandPromise,
            vm.EnumService.getEnum('Spot', 'format').then(
                function (formats: any) {
                    vm.formats = formats;
                    vm.formats = vm.sessionData.account.allowRadio
                        ? vm.formats
                        : (vm.formats = vm.formats.filter((format: any) => format.label !== 'Radio'));
                },
                function () {
                    vm.formats = [];
                }
            ),
            vm.EnumService.getEnum('Spot', 'airStatus').then(
                function (airStatuses: any) {
                    vm.promoTypes = airStatuses;
                },
                function () {
                    vm.promoTypes = [];
                }
            ),
        ]);
    }

    /* INITIALIZATION */
    $onInit() {
        let vm = this;
        vm._loadAssociatedData();

        vm.$scope.$on('spotFileUploadReady', function (event: any, fileUploaded: any) {
            vm.fileUploaded = fileUploaded;
        });
    }
}
