/** ONCE MIGRATION OF ALL COMPONENTS, SERVICES, DIRECTIVES ARE COMPLETED,
 * THIS FILE HAVE TO CHANGE TO MODULE WITH LOADING RESPECTIVE COMPONENTS
 */

export const guidesController = {
    selector: 'guidesController',
    bindings: {},
    template: '<app-guides></app-guides>',
    controller: class GuidesController {
    }
}