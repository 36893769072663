/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.makelist').controller('OrderConfirmationDialogController', ['$state', 'loginService', '$mdDialog', 'submittedSpots', 'StatusService', 'AssetResourceFactory', function ($state, loginService, $mdDialog, submittedSpots, StatusService, AssetResourceFactory) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    vm.spotThumbs = {};
    /* BINDABLE : DATA */

    /* BINDABLE : METHODS*/

    vm.cancel = cancel;
    vm.statusService = StatusService;
    vm.readStatus = readStatus;
    vm.getThumbnail = getThumbnail;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function cancel() {
      $mdDialog.cancel();
      $state.reload();
    }

    function readStatus(spot) {
      return StatusService.readOrderStatus(spot);
    }

    function getThumbnail(isci, uuid) {
      AssetResourceFactory.getImageAsset({
        uuid: uuid
      }, {}, function success(asset) {
        var blob = new Blob([asset.data], {
          type: asset.config['Content-Type']
        });
        vm.spotThumbs[isci] = URL.createObjectURL(blob);
      }, function failure(err) {// Unable to retrieve the image asset for the thumbnail
      });
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them


    function _parseDestinationString(destinations) {
      var destinationsDisplayString = [];
      var results = destinations.reduce(function (r, a) {
        if (!a.DestinationType) {
          a.DestinationType = {
            id: 0,
            label_property: 'No Type'
          };
        }

        r[a.DestinationType.id] = r[a.DestinationType.id] || [];
        r[a.DestinationType.id].push(a);
        return r;
      }, Object.create(null));
      angular.forEach(results, function (result) {
        var destinationName = [];
        angular.forEach(result, function (obj) {
          destinationName.push(obj.name);
        });
        destinationsDisplayString.push(result.length + ' ' + result[0].DestinationType.label_property + ' (' + destinationName.join(', ') + ')');
      });
      destinationsDisplayString = destinationsDisplayString.join(', ');
      return destinationsDisplayString;
    }
    /**
     * Initialization for this controller.
     */


    function _init() {
      vm.submittedSpots = angular.copy(submittedSpots);

      for (var i = 0; i < vm.submittedSpots.length; i++) {
        vm.submittedSpots[i].statusBar = vm.readStatus(vm.submittedSpots[i]);
        vm.submittedSpots[i].destinationsDisplayString = _parseDestinationString(vm.submittedSpots[i].destinations);
      }

      vm.submittedSpots = vm.submittedSpots.map(function (data, ind, list) {
        if (data.ThumbnailAsset && data.ThumbnailAsset.AssetContent && data.ThumbnailAsset.AssetContent.contentUuid) {
          // We only ever retrieve one thumbnail, so 0 is okay here
          vm.getThumbnail(data.Spot.isci, data.ThumbnailAsset.AssetContent.contentUuid);
        }

        return data;
      });
    }
  }]);
})();