/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.makelist').controller('AddDestinationDialogController', ['$state', 'loginService', '$mdDialog', '$q', 'DestinationResourceFactory', 'currentDestinations', 'destinationList', function ($state, loginService, $mdDialog, $q, DestinationResourceFactory, currentDestinations, destinationList, spotType) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.destinationSearchText = null;
    vm.selectedDestination = null;
    vm.allDestinations = null;
    vm.destinationTypes = [];
    vm.destinations = {};
    vm.selectAll = false;
    vm.destinationList = destinationList;
    /* BINDABLE : METHODS*/

    vm.cancel = cancel;
    vm.save = save;
    vm.findDestinations = findDestinations;
    vm.selectAllDestinations = selectAllDestinations;
    vm.selectDestinationTypeCategory = selectDestinationTypeCategory;
    vm.checkSelectAll = checkSelectAll;
    vm.selectDestination = selectDestination;
    vm.checkSelectAllDestinatonType = checkSelectAllDestinatonType;
    vm.filterSearchDestinations = filterSearchDestinations;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function cancel() {
      $mdDialog.cancel();
    }

    function save() {
      var selectedDestinations = _filterSelectedDestinations();

      $mdDialog.hide(selectedDestinations);
    }

    function selectAllDestinations() {
      for (var i = 0; i < vm.destinationTypes.length; i++) {
        var destType = vm.destinationTypes[i];
        destType.selected = vm.selectAll;
        vm.selectDestinationTypeCategory(destType);
      }
    }

    function selectDestinationTypeCategory(destType) {
      var category = vm.destinations[destType.label_property];

      for (var i = 0; i < category.length; i++) {
        category[i].selected = destType.selected;
      }
    }

    function findDestinations(searchTerm) {
      var deferred = $q.defer();
      DestinationResourceFactory.getAll({
        callLetter: searchTerm
      }, function success(destinations) {
        deferred.resolve(destinations.data);
      }, function failure(err) {
        deferred.resolve([]);
        console.debug(err);
      });
      return deferred.promise;
    }

    function checkSelectAll() {
      var isDestinationAllSelected = true;

      for (var i = 0; i < vm.destinationTypes.length; i++) {
        var destType = vm.destinationTypes[i];

        if (!destType.selected) {
          isDestinationAllSelected = false;
        }
      }

      vm.selectAll = isDestinationAllSelected;
    }

    function checkSelectAllDestinatonType(value) {
      var isCategoryAllSelected = true;
      var category = vm.destinations[value];

      for (var i = 0; i < category.length; i++) {
        if (!category[i].selected) {
          isCategoryAllSelected = false;
          break;
        }
      }

      for (var _i = 0; _i < vm.destinationTypes.length; _i++) {
        if (vm.destinationTypes[_i].label_property === value) {
          var destType = vm.destinationTypes[_i];
          destType.selected = isCategoryAllSelected;
          break;
        }
      }

      checkSelectAll();
    }

    function selectDestination(selectedDest) {
      angular.forEach(vm.destinations, function (destType, key) {
        for (var i = 0; i < destType.length; i++) {
          if (_.isEqual(destType[i], selectedDest)) {
            destType[i].selected = true;
            break;
          }
        }
      });
    }

    function filterSearchDestinations(searchTerm) {
      var regex = new RegExp(searchTerm.toLowerCase(), 'g');
      var matchedDests = vm.allDestinations.filter(function (dest) {
        return dest.name.toLowerCase().match(regex);
      });

      if (matchedDests.length == 1) {
        vm.selectedDestination = matchedDests[0];
      }

      return matchedDests;
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them


    function _splitByDestinationTypes(destinations, checkOnly) {
      for (var i = 0; i < destinations.length; i++) {
        var dest = destinations[i];
        var destType = dest.DestinationType;
        destType = destType ? destType : {
          label_property: 'No Type'
        };
        destType.label_property = destType.label_property ? destType.label_property : destType.labelProperty;
        var labelProperty = destType.label_property;

        if (!vm.destinations[labelProperty]) {
          vm.destinations[labelProperty] = [];
          dest.selected = _checkIfCurrentDestination(dest);

          if (!checkOnly) {
            vm.destinations[labelProperty].push(dest);
            vm.destinationTypes.push(destType);
          }
        } else {
          dest.selected = _checkIfCurrentDestination(dest);

          if (!checkOnly) {
            vm.destinations[labelProperty].push(dest);
          }
        }

        checkSelectAllDestinatonType(destType.label_property);
      }
    }

    function _filterSelectedDestinations() {
      var selected = {};
      angular.forEach(vm.destinations, function (destType, key) {
        for (var i = 0; i < destType.length; i++) {
          var dest = destType[i];

          if (dest.selected) {
            if (!selected[key]) {
              selected[key] = [];
              selected[key].push(dest);
            } else {
              selected[key].push(dest);
            }
          }
        }
      });
      return selected;
    }

    function _checkIfCurrentDestination(dest) {
      var isCurrentDest = false;

      for (var index in currentDestinations) {
        if (dest.id === currentDestinations[index].id && dest.name === currentDestinations[index].name) {
          isCurrentDest = true;
        }
      }

      return isCurrentDest;
    }
    /**
     * Initialization for this controller.
     */


    function _init() {
      DestinationResourceFactory.getAll({
        onlyGranted: true
      }, function success(destinations) {
        vm.allDestinations = destinations.data.rows;

        _splitByDestinationTypes(destinations.data.rows);

        if (vm.destinationList !== null) {
          angular.forEach(vm.destinationList, function (value, key) {
            _splitByDestinationTypes(vm.destinationList[key], true);
          });
        }
      }, function failure(err) {
        vm.allDestinations = [];

        _splitByDestinationTypes([]);
      });
    }
  }]);
})();